<template>
  <b-modal no-fade v-model="dialog.show" size="sm"  :id="`modal-smtp-${uniqueId}`"
           :hide-footer="true" centered @close="onDialogClose"
           no-close-on-backdrop
  >
    <template #modal-title>
     <span v-if="loading !== true && error == null && smtp != null && smtp.id != null">
       {{ smtp.email }}
     </span>
    </template>
    <div style="min-height: 600px">
      <div v-if="loading !== true && error == null">
        <smtp-edit-details v-bind:new-smtp="isNewSmtp" v-model="smtp" @close="closeModal" />
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else-if="loading !== true && error != null">
        <b-alert show variant="danger" >
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{$t('operations.loading')}}</b-spinner>
      </div>
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import { mapActions } from 'vuex'
import SmtpEditDetails from '@/components/partial/smtps/SmtpEditDetails'
import Smtp from '@/model/smtp/Smtp'

export default {
  name: 'smtpModal',
  components: { SmtpEditDetails },
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewSmtp: false,
    smtp: {}
  }),
  watch: {
    queryParams:{
      handler(){
        if(this.queryParams.smtpId == null) {
          this.closeModal()
          return
        }
        if (this.queryParams.smtpId !== this.id) {
          this.showModal(this.queryParams.smtpId)
        }
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid
    if(this.queryParams.smtpId != null) {
      this.showModal(this.queryParams.smtpId)
    }
  },
  methods: {
    ...mapActions('smtp', ['getSmtpById']),
    showModal(id) {
      this.id = id
      this.loading = true
      this.dialog.show = true
      this.dialog.tab = 0
      this.isNewSmtp = this.id == null || this.id === 0;
      if(!this.isNewSmtp) {
        this.refreshData()
      }else {
        this.smtp = Smtp()
        this.error = null
        this.loading = false
      }
      this.pushState({smtpId: id, tab: 0, })
    },
    closeModal(){
      this.dialog.show = false;
      this.smtp = {}
      this.onDialogClose()
    },
    onDialogClose(){
      this.id = null;
      this.pushState({smtpId: null, tab: null, })
    },
    refreshData() {
      let $this = this;
      this.getSmtpById(this.id)
          .then(smtp => {
            $this.smtp = smtp;
            $this.error = null
            $this.loading = false
          }, err => {
            $this.error = $this.getErrorDescription(err)
            $this.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>
